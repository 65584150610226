import React, { useState } from "react"
import * as LOGO from "../img/logo.svg"
import {
  Collapse,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap"

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Navbar color="light" light>
      <NavbarBrand className="mr-auto" href="/">
        <img src={LOGO} alt="Sprancer" height="40" />
      </NavbarBrand>
      <a
        className="ml-auto mr-3 d-none d-md-block"
        href="https://apps.apple.com/us/app/sprancer/id1545872777"
      >
        <img
          src="https://www.sprancer.com/appstorebadge.png" //src="https://www.sprancer.com/appstorebadge.png"
          alt="Download on the App Store"
          height={38}
        />
      </a>
      <a
        className="mr-3 d-none d-md-block"
        href="https://play.google.com/store/apps/details?id=com.sprancer.app"
      >
        <img
          src="https://www.sprancer.com/googleplaybadge.png" //src='https://www.sprancer.com/googleplagebadge.png'
          alt="Get it on Google Play"
          height={38}
        />
      </a>
      <NavbarToggler className="p-2 bg-primary text-light" onClick={toggle}>
        Sign In
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          <NavItem className="ml-auto">
            <a
              className="my-1 btn btn-primary"
              href="https://app.sprancer.com/login"
            >
              Customer Sign In
            </a>
          </NavItem>
          <NavItem className="ml-auto">
            <a
              className="my-1 btn btn-primary"
              href="https://biz.sprancer.com/user/account"
            >
              Business Sign In
            </a>
          </NavItem>
        </Nav>
      </Collapse>
      <div className="d-md-none mb-1 container px-0 justify-content-between">
        <a
          className="mt-2 "
          href="https://apps.apple.com/us/app/sprancer/id1545872777"
        >
          <img
            src="https://www.sprancer.com/appstorebadge.png" //src="https://www.sprancer.com/appstorebadge.png"
            alt="Download on the App Store"
            height={38}
          />
        </a>
        <a
          className="mt-2"
          href="https://play.google.com/store/apps/details?id=com.sprancer.app"
        >
          <img
            src="https://www.sprancer.com/googleplaybadge.png" //src='https://www.sprancer.com/googleplagebadge.png'
            alt="Get it on Google Play"
            height={38}
          />
        </a>
      </div>
    </Navbar>
  )
}
